import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'

const Products = ({ products }) => (
  <div>
    {products.map(product => (
      <div key={product.title} className="custom-index-products columns">
        <div className="column product-mobile">
          <div className="rows">
            <div className="row title">
              {product.title}
            </div>
            <div className="row description">
              {product.description}
            </div>
          </div>
        </div>
        <div className="features">
          {product.features.map(feature => (
            <div className="feature" key={feature.feature}>
              • {feature.feature}
            </div>
          ))}
        </div>
        <div className="column">
          <div className="rows">
            <div className="row title product-desktop">
              {product.title}
            </div>
            <div className="row description product-desktop">
              {product.description}
            </div>
            <div className="row link-product">
              <button>
                <Link
                  to={product.link}
                >
                  View Product
              </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

export const IndexPageTemplate = ({
  image,
  title,
  subtitle,
  products,
}) => (
    <div>
      <div
        className="full-width-image custom-index-header-image"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
            })`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
          position: 'absolute',
        }}
      >
      </div>
      <div className="full-width-image custom-index-header">
        <div
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-2-widescreen custom-title"
          style={{
            color: 'white',
            padding: '0.25em',
            position: 'absolute'
          }}
        >
          {title}
          
        </div>

      </div>
      <section className="section custom-index-section">
        <div className="container">
          <div className="section">
            <Products products={products} />
          </div>
        </div>
      </section>
    </div>
  )

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  products: PropTypes.array
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        products={frontmatter.products}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(id: {eq: $id }) {
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        products {
          title
          description
          link
          features {
            feature
          }
        }
      }
    }
  }
`
